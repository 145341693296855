/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as e } from "../../chunks/tslib.es6.js";
import { property as a } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as c } from "../../core/accessorSupport/decorators/subclass.js";
const t = t => {
  let l = class extends t {
    constructor() {
      super(...arguments), this.minScale = 0, this.maxScale = 0;
    }
    get effectiveScaleRange() {
      const e = {
          minScale: this.minScale,
          maxScale: this.maxScale
        },
        a = this.parent;
      a && "effectiveScaleRange" in a && r(e, a.effectiveScaleRange);
      const c = this._get("effectiveScaleRange");
      return c && c.minScale === e.minScale && c.maxScale === e.maxScale ? c : e;
    }
  };
  return e([a({
    type: Number,
    nonNullable: !0,
    json: {
      write: !0
    }
  })], l.prototype, "minScale", void 0), e([a({
    type: Number,
    nonNullable: !0,
    json: {
      write: !0
    }
  })], l.prototype, "maxScale", void 0), e([a({
    readOnly: !0
  })], l.prototype, "effectiveScaleRange", null), l = e([c("esri.layers.mixins.ScaleRangeLayer")], l), l;
};
function r(e, a) {
  return e.minScale = e.minScale > 0 ? a.minScale > 0 ? Math.min(e.minScale, a.minScale) : e.minScale : a.minScale, e.maxScale = e.maxScale > 0 ? a.maxScale > 0 ? Math.max(e.maxScale, a.maxScale) : e.maxScale : a.maxScale, e;
}
export { t as ScaleRangeLayer };